<template>
    <div class="w3-theme-l1" style="">
        <div class="w3-container" style="padding-top:10px">
            <Toolbar  
                :click-add="function(){}"
				:statusAdd="false"
                :click-refresh="loadData"
                :click-print="function(){}"
				:statusPrint="false"
				:statusExport="false"
                :set-filter="setFilter"
                :field-find="filters.fields"
                :data   = "recs"
                :fields = "json_fields"
                type    = "xls"
                name    = "pasien.xls"
                help = ""
            />
            <div @mouseover="grid = true" @mouseleave="grid = false">
                <vue-scrolling-table v-bind:style="'height:'+  (screenHeight-140) +'px;'" :class="'w3-table w3-striped w3-bordered w3-white w3-small freezeFirstColumn freezeLastColumn'" :include-footer="true" :dead-area-color="'w3-theme'" :scroll-horizontal="false" id="grid">
                    <template slot="thead">
                        <tr class="w3-border-right">
                            <th style="min-width:50px;" class="w3-light-grey w3-border-0">
                                No
                            </th>
                            <th v-for="fl in filterByShow" :key="fl.key" :style="'min-width:' + fl.width+
                            (fl['sort']['status']?';cursor:pointer':'')"
                                class="w3-light-grey w3-border-0" >
                                <div v-on:click="setFilterTable(fl)">{{fl.name}}
                                    <span v-if="fl['sort']['status']">
                                        <i class="fa"
                                            :class="fl['sort']['asc']=='asc'?'fa-sort-asc':(fl['sort']['asc']=='desc'?'fa-sort-desc':'fa-arrows-v')"></i>
                                    </span>
                                </div>
                            </th>
                        </tr>
                    </template>
                    <template slot="tbody">
                        <tr :id="'grid-' + index" @mouseover="dtl['pilih']=true" @mouseleave="dtl['pilih']=false" v-for="(dtl, index) in recs"  style="cursor: pointer;" :class="(dtl.pilih==true?'bayangan w3-theme-l4':(index == selectedRow ?'bayangan w3-theme-l4':'w3-opacity-min'))"  :key="dtl.pasien_code" v-on:dblclick="showEdit(dtl, index);" class="w3-text-black">
                            <td style="min-width:50px;" class="w3-light-grey w3-border-0 w3-border-right">
                                <i :class="index == selectedRow ?'fa fa-caret-right':''"></i>{{index+((pages.halaman-1)*pages.batas)+1}}
                            </td>
                            <td v-on:click="setKlikRow(index, dtl)" :style="'min-width:'+findObjFind('width','pasien_nik')" class="w3-border-0 w3-border-right">
                                <span v-html="setTextFind(dtl.pasien_nik,'pasien_nik')"></span>
                            </td>
                            <td v-on:click="setKlikRow(index, dtl)" :style="'min-width:'+findObjFind('width','pasien_name')" class=" w3-border-0 w3-border-right">
                                <span v-html="setTextFind(dtl.pasien_name,'pasien_name')"></span>
                            </td>
                            <td v-on:click="setKlikRow(index, dtl)" :style="'min-width:'+findObjFind('width','pasien_sex')" class=" w3-border-0 w3-border-right">
                                
                            </td>
                            <td v-on:click="setKlikRow(index, dtl)" :style="'min-width:'+findObjFind('width','date_edit')" class=" w3-border-0 w3-border-right">
                                {{dtl.date_edit | moment("DD-MM-yyyy")}}
                            </td>
                            <td v-on:click="setKlikRow(index, dtl)" :style="'min-width:'+findObjFind('width','user_edit')" class=" w3-border-0 w3-border-right">
                                <span v-html="setTextFind(dtl.user_edit,'user_edit')"></span>
                            </td>
                            <td style="padding: 3px 0px 0px 10px;" :style="'min-width:' + findObjFind('width','pasien_status')" class=" w3-border-0 w3-center w3-border-right w3-border-right" >
                                <div v-on:click="setKlikRow(index, dtl)" style="position: relative;" :style="'min-width:' + findObjFind('width','pasien_status')">
                                    <span v-html="setStatus(dtl.pasien_status)"></span>
                                    <div v-show="dtl['pilih']==true && dtl.pasien_status=='T'" style="position: absolute; top: -5px; left: -5px; text-align:center;" class="w3-opacity-off">
                                        <button style="padding: 5px 7px 5px 7px; margin-right:5px;" class="w3-btn w3-circle w3-tiny w3-theme-d3" v-on:click="showEdit(dtl, index)"><i class="fa fa-pencil w3-large"></i></button>
                                        <button style="padding: 5px 7px 5px 7px; margin-right:5px;" class="w3-btn w3-circle w3-tiny w3-red" v-on:click="showDelete(dtl, index)"><i class="fa fa-trash-o w3-large"></i></button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </template>
                    <template slot="tfoot">
                        <tr style="display: block;">
                            <td style="padding: 0px;" :style="'display: contents;'">
                                <Paging class="w3-center"
                                    :click-handler="loadPage"
                                    :prev-text="'Prev'"
                                    :next-text="'Next'"
                                    :container-class="'w3-bar'"
                                    :page-count="pages.ttlhalaman"
                                    :page-range="10"
                                    :margin-pages="2"
                                    :value="pages.halaman">
                                </Paging>
                            </td>
                        </tr>
                    </template>
                </vue-scrolling-table>
            </div>
        </div>
        
    </div>
</template>
<script>
/* eslint-disable */
import moment from "moment";
export default {
    head: {
        title: {
          inner: 'Pasien'
        },
    },
    name: 'pasien',
    components: {},
    data() {
        return {
            err:'',
            grid : false,
            screenWidth : 0,
            screenHeight : 0,
            tableHeight : 0,
            selectedRow : 0,
            errorMessage : {pasien_name:'',pasien_nik:''},
            fieldRules : [],
            recs : [],
            rec : {pasien_code:'', pasien_name : '', pasien_nik:'', pasien_status:'T'},
            pages : {halaman : 1, batas : 0, ttlhalaman : 0, ttlrec:0, autoadd :'F'},
            filters : {
                operator : 'AND',
                fields: [
                    {fieldFind:'', key : 'pasien_code', type: 'text', name : 'Kode', filter : false, width : '', grid : false},
                    {fieldFind:'', key : 'pasien_nik', type: 'text', name : 'Kode', filter : true, width : '80px', grid : true, sort: {status: true, asc: 'asc'}},
                    {fieldFind:'', key : 'pasien_name', type: 'text', name : 'Nama', filter : true, width : '150px', grid : true, sort: {status: true, asc: '-'}},
                    {fieldFind:'', key : 'pasien_sex', type: 'select', name : 'Sex', filter : true, width : '50px', grid : true, valueFind: [{key:'L',label:'Laki-Laki'},{key:'P',label:'Perempuan'}], sort: {status: true, asc: '-'}},
                    {fieldFind:'', key : 'pasien_alamat', type: 'text', name : 'Alamat', filter : true, width : '', grid : false, sort: {status: true, asc: '-'}},
                    {fieldFind:'', key : 'pasien_rt', type: 'text', name : 'RT', filter : true, width : '', grid : false, sort: {status: true, asc: '-'}},
                    {fieldFind:'', key : 'pasien_rw', type: 'text', name : 'RW', filter : true, width : '', grid : false, sort: {status: true, asc: '-'}},
                    {fieldFind:'', key : 'pasien_kelurahan', type: 'text', name : 'Kelurahan', filter : true, width : '', grid : false, sort: {status: true, asc: '-'}},
                    {fieldFind:'', key : 'pasien_kecamatan', type: 'text', name : 'Kecamatan', filter : true, width : '', grid : false, sort: {status: true, asc: '-'}},
                    {fieldFind:'', key : 'pasien_nobpjs', type: 'text', name : 'NO BPJS', filter : true, width : '', grid : false, sort: {status: true, asc: '-'}},
                    {fieldFind:'', key : 'pasien_foto_ktp', type: 'text', name : 'Foto KTP', filter : true, width : '', grid : false, sort: {status: true, asc: '-'}},
                    {fieldFind:'', key : 'pasien_foto_bpjs', type: 'text', name : 'Foto BPJS', filter : true, width : '', grid : false, sort: {status: true, asc: '-'}},
                    {fieldFind:'', key : 'user_entry', type: 'text', name : 'User Entry', filter : false, width : '', grid : false},
                    {fieldFind:'', key : 'date_entry', type: 'text', name : 'Date Entry', filter : false, width : '', grid : false},
                    {fieldFind:'', key: "date_edit",type: "text",name: "Date Edit",filter: false,width: "125px",grid: true, sort: {status: false, asc: '-'}},
                    {fieldFind:'', key: "user_edit",type: "text",name: "Entry",filter: false,width: "125px",grid: true, sort: {status: false, asc: '-'}},
                    {fieldFind:'', key : 'pasien_status', type: 'select', name : 'Status', filter : true, width : '80px', grid : true, valueFind: [{key:'T',label:'Aktif'},{key:'F',label:'Tidak Aktif'}], sort: {status: false, asc: '-'}},
                ],
                find : [],
                order: ""
            },
            json_fields : {'Kode' : 'pasien_code','Nama' : 'pasien_name'},            
        };
    },
    methods : {
        findObjFind(key, val){
            return this.findObj(this.filters.fields,'key',val)[key];
        },
        setTextFind(txt,field){
            let fieldvalue = "";
            let ketemu = false;
            const self = this;
            if(self.filters.find!==undefined )
                if ( self.filters.find.length >0 ) {
                    self.filters.find.map(function(value){
                        if(value.field ===field){
                            ketemu = true;
                        }
                        if(fieldvalue!=='null')
                            fieldvalue += ((fieldvalue!=='')?';':'')+ value.fieldValue ;
                        else
                            fieldvalue += ((fieldvalue!=='null')?';':'')+ value.fieldValue ;
                    });
                }
            if(ketemu){
                return self.sethighlight(txt,fieldvalue);
            }else{
                return txt;
            }
        },
        loadPage (pageNum){
            this.pages.halaman = pageNum;
            this.loadData();
        },
        setFilter(filter, operator){
            this.filters.find = filter;
            this.filters.operator = operator;
            this.loadData();
        },
        loadData(){
            const self = this;
            let vparams = {
                operator: self.filters.find.length > 0 ? self.filters.operator : "AND",
                kondisi: "",
                field: "",
                fieldvalue: "",
                limit: self.pages.batas,
                offset: eval(self.pages.batas + "*(" + self.pages.halaman + "-1)"),
                order: self.filters.order
            };

            if (self.filters.find.length > 0) {
                self.filters.find.map(function(value) {
                    vparams.kondisi += (vparams.kondisi !== "" ? ";" : "") + value.kondisi;
                    vparams.field += (vparams.field !== "" ? ";" : "") + value.field;
                    if (vparams.fieldvalue !== "null")
                        vparams.fieldvalue += (vparams.fieldvalue !== "" ? ";" : "") + value.fieldValue;
                    else
                        vparams.fieldvalue += (vparams.fieldvalue !== "null" ? ";" : "") + value.fieldValue;
                });
            }
            self.apiPost('/antrian/pasien/search',vparams,function(response){
                if(response.success==true){
                    self.selectedRow = -1;
                    self.recs = response.rec;
                    self.pages.ttlrec = parseFloat(response.ttlrec);
                    self.pages.ttlhalaman = Math.ceil(parseFloat(response.ttlrec)/self.pages.batas);
                    self.fieldRules = response.rules;
                }else{
                    console.log('Gagal');
                }
            }, function(err){
				console.log(err);
			});
        },
        setKlikRow(index, dtl){
            const self = this;
            self.selectedRow = index;
            self.rec = Object.assign({}, dtl);
        },
        setStatus(dt) {
            if (dt === "T") {
                return "<div class='w3-green w3-center w3-round' style='width:50px;margin-top:5px;'>Active</div>";
            } else {
                return "<div class='w3-red w3-center w3-round' style='width:50px;margin-top:5px;'>In Active<div>";
            }
        },
        setFilterTable(dt){
            if(!this.setFilterHeader(dt, this.filters)) {
                this.loadData();
            }
        }
    },
    computed :{
        filterByShow(){
            return  this.filters.fields.filter(event => {
                return event.grid == true;                
            });
        },
    },
    filters: {
        toRp (value) {
            return `${Intl.NumberFormat('de-DE').format(value)}`
        }
    },
    watch: {
        '$route': function(){
        }
    },
    mounted(){
        const self = this;
        this.pages = {halaman: 1,batas: this.perPage,ttlhalaman: 0,ttlrec: 0,autoadd: "T"};
        this.screenWidth = screen.width;
        this.screenHeight = window.innerHeight;
        this.eventGrid(self, 'grid', 'selectedRow', 'recs');
        this.getGridColumnmAutoWidth('grid',self.filters.fields, false);
        this.setFilterHeaders(this.filters);
        this.loadData();
    }
};
</script>